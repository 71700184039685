<template>
  <v-dialog
    v-model="dialog"
    :attach="notPlacedInGuide ? false : '.guide__activities'"
    persistent
    max-width="400px"
    style="z-index: 10000"
  >
    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold">Program Checkout</h5>
        <div class="ml-auto">
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </v-card-title>

      <v-divider v-if="!mustSponsor" />

      <v-container>
        <div class="d-flex justify-center flex-column">
          <v-dialog
            v-model="dialogUnsponsored"
            :attach="notPlacedInGuide ? false : '.guide__activities'"
            persistent
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="!mustSponsor && value && value.data && value.data.isUnsponsoredProgram"
                v-bind="attrs"
                outlined
                class="mt-2 mb-2 font-weight-black"
                x-large
                rounded
                depressed
                v-on="on"
                @click="dialogUnsponsored = true"
                ><v-icon left>mdi-set-right</v-icon>Join Unsponsored</v-btn
              >
            </template>
            <v-card class="m-use-token-dialog--unsponsored">
              <v-card-title class="heading-wrapper">
                <div class="overline font-weight-bold">
                  Are you sure you want to join this program unsponsored?
                </div>

                <div class="ml-auto">
                  <v-btn icon @click="dialogUnsponsored = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-container>
                <div class="d-flex justify-center px-auto py-3 text-center">
                  <h5 class="grey--text">
                    You will only be able to go as far as the "{{ value.data.unsponsoredAdk }}"
                    activity and have certain feature disability until you're sponsored
                  </h5>
                </div>

                <!-- JEROLD THIS IS THE FEATURE I'M TALKING ABOUT WHERE PARTICIPANTS MUST ENTER "WHO THEY HEARD ABOUT PILOTCITY FROM" BEFORE THE JOIN UNSPONSORED BUTTON UNLOCKS, STORE THIS IN THE STUDENT DOC ROOT  -->
                <div class="my-6">
                  <v-text-field
                    v-model="referralRep"
                    rounded
                    outlined
                    label="Who did you hear about PilotCity from?"
                    placeholder="Derick"
                  ></v-text-field>
                </div>
                <div class="d-flex justify-center flex-row align-center back-wrapper">
                  <v-btn
                    class="ma-2"
                    x-large
                    rounded
                    outlined
                    depressed
                    @click="dialogUnsponsored = false"
                    >Go back</v-btn
                  >
                  <v-btn
                    class="ma-2 font-weight-bold"
                    :disabled="!referralRep"
                    rounded
                    x-large
                    dark
                    depressed
                    :loading="checkingOut"
                    @click="programCheckout('use_as_unsponsored')"
                    >Join Unsponsored</v-btn
                  >
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="sponsorshipDialog"
            :attach="notPlacedInGuide ? false : '.guide__activities'"
            persistent
            max-width="500px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="mustSponsor"
                v-bind="attrs"
                class="mt-2 mb-2 font-weight-bold white--text"
                x-large
                color="red"
                :disabled="!!value.data.sponsorDetails"
                rounded
                depressed
                @click="sponsorshipDialog = true"
                v-on="on"
                ><v-icon left>mdi-trophy</v-icon>Get Sponsored</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <v-icon large color="red">mdi-trophy</v-icon>
                <div class="headline font-weight-bold ml-4">Get Sponsored</div>

                <div class="ml-auto">
                  <v-btn icon @click="sponsorshipDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-container class="pa-6">
                <div class="d-flex justify-center flex-column">
                  <v-form v-model="applyForSponsorshipValid">
                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <a-autocomplete
                        v-model="studentResidence"
                        :error-message="errors"
                        is-rounded
                        left-icon="mdi-map-marker-radius"
                        placeholder="Residence Address"
                        label="Residence"
                        type="street"
                        style="margin-bottom: 16px"
                        @val="setStreetValue"
                      />
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <v-text-field
                        v-model="city"
                        :error-message="errors"
                        :rules="[rules.required]"
                        prepend-icon="mdi-telegram"
                        class="mb-4"
                        rounded
                        label="City"
                        hide-details
                        outlined
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <v-text-field
                        v-model="state"
                        :error-message="errors"
                        :rules="[rules.required]"
                        prepend-icon="mdi-telegram"
                        class="mb-4"
                        rounded
                        label="State"
                        hide-details
                        outlined
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <a-autocomplete
                        v-model="studentSchool"
                        :error-message="errors"
                        is-rounded
                        left-icon="mdi-notebook"
                        placeholder="School"
                        label="School"
                        type="school"
                        style="margin-bottom: 16px"
                        @val="setSchoolValue"
                      />
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <a-autocomplete
                        v-model="schoolDistrict"
                        :error-message="errors"
                        is-rounded
                        left-icon="mdi-notebook"
                        placeholder="Search School District"
                        label="Search School District"
                        type="school_district"
                        style="margin-bottom: 16px"
                        @val="setSchoolDistrictValue"
                      />
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim rules="required">
                      <v-select
                        v-model="ethnicityCulture"
                        :items="ETHNICITY"
                        :error-messages="errors"
                        multiple
                        hide-details
                        class="mb-4"
                        prepend-icon="mdi-account-group"
                        rounded
                        label="Ethnicity & Culture"
                        outlined
                      ></v-select>
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim>
                      <v-combobox
                        v-model="hobbies"
                        :items="hobbiesOptions"
                        :search-input="hobbiesSearch"
                        :error-messages="errors"
                        rounded
                        prepend-icon="mdi-telegram"
                        label="Do you want to add any of this hobbies?"
                        multiple
                        small-chips
                        hide-details
                        outlined
                        class="mb-4"
                      >
                        <template #selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            small
                            @click="parent.selectItem(item)"
                          >
                            <span class="">
                              {{ item }}
                            </span>
                            <v-icon small> mdi-close </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </validation-provider>

                    <validation-provider v-slot="{ errors }" slim>
                      <v-combobox
                        v-model="studentDocument"
                        :items="eligibilityOptions"
                        :search-input="eligibilitySearch"
                        :error-messages="errors"
                        rounded
                        prepend-icon="mdi-check-box-multiple-outline"
                        label="Do you have any of the following?"
                        multiple
                        small-chips
                        hide-details
                        outlined
                        class="mb-4"
                      >
                        <template #selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            small
                            @click="parent.selectItem(item)"
                          >
                            <span class="">
                              {{ item }}
                            </span>
                            <v-icon small> mdi-close </v-icon>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </validation-provider>

                    <v-text-field
                      v-model="learntPilotcity"
                      prepend-icon="mdi-telegram"
                      class="mb-4"
                      rounded
                      label="How did you hear about PilotCity?"
                      hide-details
                      outlined
                    ></v-text-field>
                    <v-btn
                      :dark="applyForSponsorshipValid"
                      :disabled="!applyForSponsorshipValid"
                      style="width: 100%"
                      color="red"
                      x-large
                      :loading="isApplying"
                      rounded
                      depressed
                      @click="applyForSponsorship"
                      >Apply</v-btn
                    >
                  </v-form>
                  <v-dialog
                    v-model="dialogApply"
                    :attach="notPlacedInGuide ? false : '.guide__activities'"
                    persistent
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title>
                        <v-container class="d-flex flex-column justify-center">
                          <div class="overline font-weight-bold justify-center">Thank you!</div>
                          <div class="headline font-weight-bold justify-center">
                            We'll be finding you a sponsor
                          </div>
                        </v-container>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-container>
                        <div class="justify-center flex-column d-flex mt-3 mb-3">
                          <v-btn
                            x-large
                            rounded
                            dark
                            depressed
                            class="ma-3"
                            @click="$router.push({ name: 'portfolio' })"
                          >
                            Go to My Programs
                          </v-btn>
                          <v-btn
                            x-large
                            rounded
                            outlined
                            depressed
                            class="ma-3"
                            @click="$router.push({ name: 'explore' })"
                          >
                            Explore more programs
                          </v-btn>
                        </div>
                      </v-container>
                    </v-card>
                  </v-dialog>
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <v-divider class="my-3"></v-divider>

          <v-dialog
            v-model="dialog2"
            :attach="notPlacedInGuide ? false : '.guide__activities'"
            persistent
            max-width="425px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                class="mt-2 mb-2 font-weight-black"
                x-large
                rounded
                depressed
                @click="dialog2 = true"
                v-on="on"
                ><v-icon left>mdi-set-right</v-icon>Use {{ tokenValue }}
                {{ tokenValue > 1 ? 'Tokens' : 'Token' }}</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <div class="overline font-weight-bold">
                  Are you sure you want to use {{ tokenValue }}
                  {{ tokenValue > 1 ? 'tokens' : 'token' }}?
                </div>

                <div class="ml-auto">
                  <v-btn icon @click="dialog2 = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-container>
                <div class="d-flex justify-center flex-row align-center">
                  <v-btn
                    class="ma-2"
                    color="red"
                    x-large
                    rounded
                    depressed
                    dark
                    @click="dialog2 = false"
                    >No</v-btn
                  >
                  <v-btn
                    class="ma-2"
                    color="green"
                    rounded
                    x-large
                    dark
                    depressed
                    :loading="checkingOut"
                    @click="programCheckout('use_token')"
                    >Yes</v-btn
                  >
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <v-btn
            color="blue"
            class="mt-2 mb-2 font-weight-bold"
            dark
            x-large
            rounded
            depressed
            @click="
              $router.push({
                name: 'payment'
              })
            "
            ><v-icon left>mdi-cash-usd</v-icon>Buy {{ tokenValue }}
            {{ tokenValue > 1 ? 'Tokens' : 'Token' }}</v-btn
          >

          <v-dialog
            v-model="dialog3"
            :attach="notPlacedInGuide ? false : '.guide__activities'"
            persistent
            max-width="500px"
            style="z-index: 10000"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                class="mt-2 mb-2 font-weight-bold"
                x-large
                color="green"
                rounded
                dark
                depressed
                @click="dialog3 = true"
                v-on="on"
                ><v-icon left>mdi-vector-link</v-icon>Enter Code</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <div class="overline font-weight-bold">Enter Sponsorship Code</div>

                <div class="ml-auto">
                  <v-btn icon @click="dialog3 = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-container>
                <div class="d-flex justify-center flex-column">
                  <v-text-field
                    v-model.trim="sponsorshipLink"
                    class="ma-4"
                    rounded
                    hide-details
                    outlined
                    placeholder="https://www.pilotcity.com/sponsor/entercode"
                  ></v-text-field>
                  <v-btn
                    :dark="sponsorshipLink.length > 0"
                    :disabled="!sponsorshipLink.length"
                    :loading="checkingOut"
                    color="green"
                    x-large
                    rounded
                    depressed
                    class="ma-4"
                    @click="programCheckout('use_claim_link')"
                    >Start Program</v-btn
                  >
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <div class="mt-3 d-flex justify-center">
            <h5 class="d-flex module-default__make-italic">
              <span v-if="!mustSponsor">No tokens? Join unsponsored.</span>
              <span v-else>No tokens? Get sponsored.</span>
            </h5>
          </div>
          <v-container>
            <v-row justify="center">
              <v-tooltip bottom color="black" content-class="white--text" :open-delay="300">
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="white--text mr-2 ml-2 mt-2 mb-2"
                    color="black"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="tokenDescDialog = true"
                  >
                    <v-icon>mdi-help-circle</v-icon>
                  </v-btn>
                </template>

                <span> Tokens are used to sponsor a participant for a program. </span>
              </v-tooltip>
            </v-row>
          </v-container>

          <v-dialog v-model="tokenDescDialog" width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">What is a Token?</v-card-title>

              <v-card-text>
                <br />
                Tokens are used to sponsor a participant for a program. You can purchase a token for
                yourself or someone else by clicking below.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="tokenDescDialog = false">
                  <v-icon small class="mr-2">mdi-close</v-icon> Dismiss
                </v-btn>
                <v-btn color="blue" text @click="redirectToPayment">
                  <v-icon small class="mr-2">mdi-arrow-right</v-icon> Go to Payment
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-container>

      <v-divider></v-divider>

      <div class="d-flex justify-center">
        <v-btn
          :ripple="false"
          class="explore-card__button font-weight-bold ml-1 mr-1 mt-6 mb-6"
          rounded
          small
          disabled
          depressed
          ><v-icon left> mdi-set-none </v-icon> Balance: {{ walletBalance }}
          {{ walletBalance > 1 ? 'Tokens' : 'Token' }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { onMounted, reactive, ref, computed, watch } from '@vue/composition-api';
import store, { useUserActions, useUserState, useUserGetters } from '@/store';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import { ActionTypes } from '@/store/modules/tools/actions';
import { ETHNICITY } from '@/constants/portfolio';
import axios from 'axios';

export default {
  components: {
    AAutocomplete
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    mustSponsor: {
      type: Boolean,
      default: false
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    updateAdk: {
      type: Function,
      default: () => {}
    },
    notPlacedInGuide: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const { user } = useUserState(['user']);
    const { updateAdkData, getTokens, addBadges } = useUserActions([
      'updateAdkData',
      'getTokens',
      'addBadges'
    ]);
    const { getUserType } = useUserGetters(['getUserType']);
    const tokenDescDialog = ref(false);
    const dialog = ref(false);
    const dialog2 = ref(false);
    const dialog3 = ref(false);
    const dialogUnsponsored = ref(false);
    const sponsorshipDialog = ref(false);
    const checkingOut = ref(false);
    const sponsorshipLink = ref('');
    const applyForSponsorshipValid = ref(false);
    const dialogApply = ref(false);
    const studentResidence = ref('');
    const city = ref('');
    const state = ref('');
    const studentSchool = ref('');
    const schoolDistrict = ref('');
    const hobbies = ref();
    const hobbiesOptions = ref([]);
    const hobbiesSearch = ref();
    const ethnicityCulture = ref([]);
    const studentFollowingOptions = ref([]);
    const eligibilityOptions = ref([
      'I receive Free or Reduced School Lunch',
      'I live without my parents',
      'My family receives food stamps or CalWORKS',
      'I have an IEP or long-term 504 Plan',
      'I am a foster child',
      'I am homeless',
      'I am a parent',
      'I am on probation',
      'I am a first generation US resident',
      'I am the first generation college student'
    ]);
    const learntPilotcity = ref('');
    const eligibilitySearch = ref('');
    const studentDocument = ref([]);
    const isApplying = ref(false);
    const walletBalance = ref();
    const tokenValue = computed({
      get: () => props?.value?.data?.tokens || 1,
      set: newVal => {
        ctx.emit('tokenValue', newVal);
      }
    });

    const getWalletBalance = async () => {
      const wallet = await getTokens({
        public_id:
          getUserType?.value && getUserType?.value === 'participant'
            ? props?.value?.data?._id
            : undefined
      });
      walletBalance.value = wallet?.personalWallet?.balance;
    };
    // if (ctx.root.$route?.params?.page === '1' || ctx.root.$route?.query?.adk === '1') {
    getWalletBalance();
    // }

    const referralRep = ref();
    const adkIndex = ref();
    const adkData = reactive({});
    watch(dialog, val => {
      if (val) {
        if (localStorage?.getItem('sponsorInfo')) {
          const sponsorInfo = JSON.parse(localStorage?.getItem('sponsorInfo'));
          if (sponsorInfo && sponsorInfo?.sponsorLink)
            sponsorshipLink.value = sponsorInfo?.sponsorLink;
          setTimeout(() => {
            dialog3.value = true;
          }, 500);
        } else {
          sponsorshipLink.value = '';
        }
      }
    });
    // const openSponsorLinkDialog = () => {
    //   dialog3.value = true;
    //   if (localStorage?.getItem('sponsorInfo')) {
    //     const sponsorInfo = JSON.parse(localStorage?.getItem('sponsorInfo'));
    //     if (sponsorInfo && sponsorInfo?.sponsorLink)
    //       sponsorshipLink.value = sponsorInfo?.sponsorLink;
    //   } else {
    //     sponsorshipLink.value = '';
    //   }
    // };

    onMounted(() => {
      if (!getUserType.value) return;
      // adkIndex.value = props?.value?.data?.adks?.findIndex(a => a?.name === 'start');
      adkData.value = props?.value?.data?.adks?.find(a => a?.name?.toLowerCase() === 'start');
    });

    async function updateAdk() {
      try {
        Object.assign(adkData.value, { referralRep: referralRep.value });
        await updateAdkData({
          program_id: props.value.data._id,
          document_type: 'Student',
          data: adkData.value
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function programCheckout(val) {
      const BASE_API = process.env.VUE_APP_REDIS;
      try {
        checkingOut.value = true;
        let API;
        await updateAdk();
        let data;
        let checkoutBySponsorLink = false;
        if (val === 'use_as_unsponsored') {
          API = `${BASE_API}/join/unsponsored`;
          data = {
            programID: ctx.root.$route.params.programId || props.value?.data?.program_id,
            userID: user.value._id
          };
        } else if (val === 'use_token') {
          API = `${BASE_API}/use-token`;
          data = {
            program_id: ctx.root.$route.params.programId || props.value?.data?.program_id,
            participant_id: user.value._id
          };
        } else {
          API = `${BASE_API}/claim`;
          data = {
            programID: ctx.root.$route.params.programId || props.value?.data?.program_id,
            userID: user.value._id,
            studentID: props.value?.data?._id,
            shareCode: sponsorshipLink.value.split('/').pop()
          };
          // check sponsorlink used or not
          const sponsorInfoData = JSON.parse(localStorage?.getItem('sponsorInfo'));
          if (
            sponsorInfoData &&
            sponsorInfoData?.sponsorLink &&
            sponsorshipLink?.value === sponsorInfoData?.sponsorLink
          ) {
            checkoutBySponsorLink = true;
          }
        }
        const res = await axios.post(API, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
            'Content-Type': 'application/json'
          }
        });
        if (res.data?.data?.value) {
          if (checkoutBySponsorLink) {
            localStorage?.removeItem('sponsorInfo');
            store.dispatch(`tools/${ActionTypes.disableBalloonPopup}`);
          }
          let badges;
          if (
            !adkData?.value?.isBadgesAdded &&
            ((props?.value?.data?.credentials &&
              typeof props?.value?.data?.credentials === 'object' &&
              props?.value?.data?.credentials?.length >= 1 &&
              props?.value?.data?.credentials?.filter(cred =>
                cred?.activities?.includes(adkData?.value?.name)
              )?.length >= 1) ||
              (props?.value?.data?.rewards &&
                typeof props?.value?.data?.rewards === 'object' &&
                props?.value?.data?.rewards?.length >= 1 &&
                props?.value?.data?.rewards?.filter(reward =>
                  reward?.activities?.includes(adkData?.value?.name)
                )?.length >= 1) ||
              (adkData?.value?.credentials && adkData?.value?.credentials?.length >= 1) ||
              (adkData?.value?.rewards && adkData?.value?.rewards?.length >= 1))
          ) {
            badges = await addBadges({
              user_id: user?.value?._id?.toString(),
              student_id: props?.value?.data?._id.toString(),
              adk_name: 'start'
            });
          }
          await props.fetchProgram();
          getWalletBalance();
          dialog.value = false;
          dialog2.value = false;
          checkingOut.value = false;
          // ctx.emit('next-page');
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Program checkout successfully',
            type: 'success',
            isShowSnackbar: true
          });
          if (badges?.data) {
            setTimeout(() => {
              store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
                message: badges?.message,
                type: 'success',
                isShowSnackbar: true
              });
            }, 2000);
          }
          setTimeout(() => {
            if (adkData?.value?.isShowFeedBackForm) {
              ctx.emit('showFeedBackForm');
            } else {
              ctx.emit('next-page');
            }
          }, 2500);
        } else if (res.data?.errorCode) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Please use valid sponsorship code',
            type: 'error',
            isShowSnackbar: true
          });
          dialog.value = false;
          dialog2.value = false;
          checkingOut.value = false;
        } else {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: "You don't have any tokens",
            type: 'error',
            isShowSnackbar: true
          });
          dialog.value = false;
          dialog2.value = false;
          checkingOut.value = false;
        }
      } catch (error) {
        console.error(error);
        console.log(error?.response?.data);
        if (error.response?.data?.error?.description) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: error.response?.data?.error?.description,
            type: 'error',
            isShowSnackbar: true
          });
        }
        checkingOut.value = false;
        dialog.value = false;
        dialog2.value = false;
      }
    }

    const rules = ref({
      required: value => !!value || 'Required.',
      allSkillsSelected: value =>
        (value && value.length === props.value.data.requiredSkills.length) || 'Required.'
    });

    async function applyForSponsorship() {
      try {
        isApplying.value = true;
        const data = {
          program_id: ctx.root.$route.params.programId,
          residence: studentResidence.value,
          city: city.value,
          state: state.value,
          school: studentSchool.value,
          schoolDistrict: schoolDistrict.value,
          hobbies: hobbies.value,
          ethnicity: ethnicityCulture.value,
          eligibility_options: studentDocument.value,
          reference: learntPilotcity.value
        };

        const res = await axios.patch(
          `${process.env.VUE_APP_SPONSOR_SERVICE}/apply-sponsorship`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );

        if (res.data.data) {
          sponsorshipDialog.value = false;
          dialog.value = false;
          await updateAdk();
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: res.data.message,
            type: 'success',
            isShowSnackbar: true
          });
          isApplying.value = false;
        }
      } catch (e) {
        console.log(e);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: e.response.data.message,
          type: 'error',
          isShowSnackbar: true
        });
        isApplying.value = false;
      }
    }

    function setStreetValue(e) {
      if (e.target) {
        studentResidence.value = e.target.value;
      } else if (e.name) {
        studentResidence.value = e.formatted_address;
      }
    }

    function setSchoolValue(e) {
      if (e.target) {
        studentSchool.value = e.target.value;
      } else if (e.name) {
        studentSchool.value = `${e.name}, ${e.formatted_address}`;
      }
    }

    function setSchoolDistrictValue(e) {
      if (e.target) {
        schoolDistrict.value = e.target.value;
      } else if (e.name) {
        schoolDistrict.value = e.formatted_address;
      }
    }
    const redirectToPayment = () => {
      ctx.root.$router.push({ name: 'payment' });
    };

    return {
      dialog,
      setSchoolValue,
      setStreetValue,
      setSchoolDistrictValue,
      rules,
      dialog2,
      dialog3,
      dialogUnsponsored,
      isApplying,
      sponsorshipDialog,
      applyForSponsorship,
      programCheckout,
      checkingOut,
      sponsorshipLink,
      applyForSponsorshipValid,
      studentResidence,
      studentSchool,
      city,
      state,
      schoolDistrict,
      studentFollowingOptions,
      ethnicityCulture,
      eligibilityOptions,
      learntPilotcity,
      dialogApply,
      ETHNICITY,
      eligibilitySearch,
      studentDocument,
      hobbies,
      hobbiesOptions,
      hobbiesSearch,
      walletBalance,
      tokenValue,
      referralRep,
      tokenDescDialog,
      adkData,
      redirectToPayment
    };
  }
};
</script>

<style lang="scss">
.guide__activities .v-dialog__content {
  max-width: calc(100% - 335px);
  left: calc(335px);
  @media (max-width: 767px) {
    max-width: initial;
    left: 20%;
  }
  @media (max-width: 575px) {
    max-width: initial;
    left: 0;
  }
}
.m-use-token-dialog--unsponsored .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--v-primary-lighten5) !important;
  color: var(--v-primary-lighten3) !important;
}
.back-wrapper {
  flex-wrap: wrap;

  button {
    width: 100%;
  }
}
.heading-wrapper {
  flex-wrap: nowrap;
  align-items: flex-start;
}
</style>
