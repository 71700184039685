



















































































































































































































/* eslint-disable no-prototype-builtins */
/* eslint-disable vue/no-mutating-props */
/* eslint-disable no-param-reassign */
import {
  computed,
  reactive,
  ref,
  toRefs,
  defineComponent,
  PropType,
  watch,
  watchEffect,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api';
import { useUserActions } from '@/store';
import moment from 'moment';
import MongoDoc from './types';

export default defineComponent({
  name: 'MModule',
  components: {
    ARenderer: () => import(`@/components/Renderer.vue`),
    AOverlayLoader: () => import('@/components/atoms/a-overlay-loader.vue'),
    MInstruction: () => import('@/components/molecules/m-instruction.vue'),
    ACTASnackbar: () => import('@/components/atoms/a-cta-snackbar.vue'),
    ATitleSnackbar: () => import('@/components/atoms/a-title-snackbar.vue')
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<MongoDoc>
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    isVisitor: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    isNotAuthenticated: {
      type: Boolean,
      default: false
    },
    userType: {
      required: true,
      type: String
    },
    userDoc: {
      required: false,
      default: Object
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    },
    operationMode: {
      type: Number,
      default: 0
    },
    adkType: {
      type: String,
      default: 'start'
    },
    studentId: {
      type: String,
      default: ''
    },
    isEditAdk: {
      type: Boolean,
      default: false
    },
    isShowCtaSnackbar: {
      type: Boolean,
      default: false
    },
    ctaButtons: {
      type: Array,
      default: () => [],
      required: false
    },
    isShowTitleSnackbar: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  setup(props: any, ctx: any) {
    const { saveActivityStartedOrEndedTime } = useUserActions(['saveActivityStartedOrEndedTime']);
    const adkIndex: any = ref();
    const isPreview = ref(false);
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('update:value', newVal);
      }
    });
    const adkTypeValue = computed({
      get: () => props.adkType,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const userDocValue = computed({
      get: () => props.userDoc,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const page = reactive({
      subpages: ['Setup', 'Presets'],
      currentPage: 'Presets'
    });

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const moduleName = ref(
      (adkTypeValue.value === 'start' && props.userType === 'organizer') ||
        (adkTypeValue.value === 'start' && props.isVisitor)
        ? `${capitalizeFirstLetter(adkTypeValue.value)} Program`
        : capitalizeFirstLetter(adkTypeValue.value)
    );
    const fieldsData = ref(programDoc.value?.data?.adks?.find(a => a.name === adkTypeValue.value));
    const fieldsDataValue = computed({
      get: () => programDoc.value?.data?.adks?.find(a => a.name === adkTypeValue.value),
      set: newVal => {
        fieldsData.value = newVal;
      }
    });
    const containerClass = ref(
      (fieldsData.value?.category === 'project' && props.userType === 'organizer') ||
        props.isVisitor
        ? 'module-edit module-edit__container'
        : ''
    );

    function initiateAdk() {
      adkIndex.value = programDoc.value?.data?.adks?.findIndex(obj => {
        if (obj) {
          return obj.name === adkTypeValue.value;
        }
        return false;
      });
      if (adkIndex.value === -1) {
        const initAdk = {
          name: adkTypeValue.value
        };
        programDoc.value?.data?.adks?.push(initAdk);
        adkIndex.value = programDoc.value?.data?.adks?.findIndex(
          a => a.name === adkTypeValue.value
        );
      }
    }

    initiateAdk();

    function setView() {
      switch (props.userType) {
        case 'organizer':
          if (
            props.operationMode === 1 &&
            fieldsData.value?.setupRequired &&
            ctx.root.$route.name !== 'Monitor'
          ) {
            page.currentPage = 'Setup';
          } else if (
            props.operationMode === 1 &&
            !fieldsData.value?.setupRequired &&
            ctx.root.$route.name !== 'Monitor'
          ) {
            page.currentPage = 'Presets';
          } else if (ctx.root.$route.name !== 'Monitor') {
            page.currentPage = 'preview';
          } else {
            page.currentPage = 'monitor';
          }
          break;
        case 'participant':
          page.currentPage = 'default';
          break;
        default:
          if (
            props.isVisitor &&
            props.operationMode === 1 &&
            fieldsData.value?.setupRequired &&
            ctx.root.$route.name !== 'Monitor'
          ) {
            page.currentPage = 'Setup';
          } else if (
            props.isVisitor &&
            props.operationMode === 1 &&
            !fieldsData.value?.setupRequired &&
            ctx.root.$route.name !== 'Monitor'
          ) {
            page.currentPage = 'Presets';
          } else if (props.isVisitor && ctx.root.$route.name !== 'Monitor') {
            page.currentPage = 'preview';
          } else {
            break;
          }
          break;
      }
    }

    setView();

    const getComponent = computed(() => {
      return `module-${page.currentPage.toLowerCase()}`;
    });

    const color = reactive({
      pilotcityColors: [
        ['#6eba80', '#3c9dcd', '#ea6764'],
        ['#eda1bf', '#fec34b', '#bdbdbd'],
        ['#ae90b0', '#f79961', '#000000']
      ],
      // ENTER ACTIVITY COLOR
      selectedColor: fieldsData.value?.styling?.borderColor
    });

    watch(
      () => props.value,
      newVal => {
        fieldsData.value = newVal?.data?.adks?.find(a => a.name === props.adkType);
        color.selectedColor = fieldsData.value?.styling?.borderColor;
      },
      { deep: true }
    );

    // calculate timer spent on each activity
    const durationFormat = ref();
    const dynamicBgColor = ref();
    let intervalId;
    const calculateTime = (time: number, idealCompletiontime: number) => {
      if (idealCompletiontime) {
        time = idealCompletiontime - time;
        if (time === 1) {
          dynamicBgColor.value = '#fec34b';
        }
        if (time < 1) {
          dynamicBgColor.value = '#ea6764';
        }
      }
      const days = moment.duration(time, 'minutes').days();
      const hours = moment.duration(time, 'minutes').hours();
      const minutes = moment.duration(time, 'minutes').minutes();
      return `${days}d:${hours}h:${minutes}m`;
    };

    function calculateTimer(adkName, idealCompletiontime) {
      const adkObject = programDoc?.value?.data?.adks?.find(adk => adk?.name === adkName);
      const adkIndexValue = programDoc?.value?.data?.adks
        ?.filter(adk => adk?.status)
        ?.findIndex(adk => adk?.name === adkName);
      let timeTaken;
      if (!adkObject?.hasOwnProperty('activityStartedOn')) {
        const startTime = moment().toISOString();
        timeTaken = 0;
        saveActivityStartedOrEndedTime({
          student_id: programDoc?.value?.data?._id?.toString(),
          adk_name: adkObject?.name,
          type: 'activityStartedOn',
          time: startTime
        });
        saveActivityStartedOrEndedTime({
          student_id: programDoc?.value?.data?._id?.toString(),
          adk_name: adkObject?.name,
          type: 'timeTaken',
          time: timeTaken
        });
        programDoc.value.data.adks[adkIndexValue].activityStartedOn = new Date(startTime);
        programDoc.value.data.adks[adkIndexValue].timeTaken = Number(timeTaken);
        // props.fetchProgram();
      } else if (
        adkObject?.hasOwnProperty('activityStartedOn') &&
        adkObject?.hasOwnProperty('timeTaken')
      ) {
        timeTaken = adkObject?.timeTaken;
      }
      durationFormat.value = calculateTime(timeTaken, idealCompletiontime);
      clearInterval(intervalId);
      intervalId = setInterval(async () => {
        timeTaken += 1;
        await saveActivityStartedOrEndedTime({
          student_id: programDoc?.value?.data?._id?.toString(),
          adk_name: adkObject?.name,
          type: 'timeTaken',
          time: timeTaken
        });
        programDoc.value.data.adks[adkIndexValue].timeTaken = Number(timeTaken);
        // await props.fetchProgram();
        durationFormat.value = calculateTime(timeTaken, idealCompletiontime);
      }, 60000);
    }

    const initiateTimer = adkName => {
      const adkObject = programDoc.value?.data?.adks?.find(adk => adk?.name === adkName);
      dynamicBgColor.value = '#6eba80';
      if (
        programDoc.value?.data?.timerType &&
        programDoc.value?.data?.timerType !== 'Deadline Completion'
      ) {
        if (
          programDoc.value?.data?.timerType === 'Count Down' &&
          adkObject?.timeInForCountDown &&
          adkObject?.idealActivityCompletionTime
        ) {
          const timeValue =
            adkObject?.timeInForCountDown === 'Hours'
              ? adkObject?.idealActivityCompletionTime * 60
              : adkObject?.idealActivityCompletionTime;
          calculateTimer(adkName, timeValue);
        } else {
          calculateTimer(adkName, null);
        }
      }
      //  else if (
      //   programDoc.value?.data?.timerType &&
      //   programDoc.value?.data?.timerType === 'Deadline Completion' &&
      //   programDoc.value?.data?.deadlineCompletion
      // ) {
      //   calculateTimer(adkName, null);
      // }
      else {
        calculateTimer(adkName, null);
      }
    };

    if (props?.userType === 'participant') {
      initiateTimer(adkTypeValue?.value);
    }

    watch(adkTypeValue, val => {
      if (val) {
        moduleName.value =
          (adkTypeValue.value === 'start' && props.userType === 'organizer') ||
          (adkTypeValue.value === 'start' && props.isVisitor)
            ? `${capitalizeFirstLetter(val)} Program`
            : capitalizeFirstLetter(val);
        const fieldsValue = programDoc?.value?.data?.adks?.find(a => a.name === val);
        fieldsData.value = fieldsValue;
        color.selectedColor = fieldsData.value.styling.borderColor;
        setView();
        containerClass.value =
          (fieldsData.value.category === 'project' && props.userType === 'organizer') ||
          props.isVisitor
            ? 'module-edit module-edit__container'
            : '';
        initiateAdk();
        if (props?.userType === 'participant') {
          initiateTimer(val);
        }
      }
    });

    watch(page, val => {
      if (val.currentPage.toLowerCase() === 'preview') {
        isPreview.value = true;
      } else {
        if (isPreview?.value) {
          setView();
        }
        isPreview.value = false;
      }
    });

    watchEffect(() => {
      if (ctx.root.$route.query.preview && adkTypeValue.value !== 'start') {
        isPreview.value = true;
      } else {
        isPreview.value = false;
      }
    });

    const getColor = computed(() => {
      return color?.selectedColor?.substring(0, 7);
    });

    const isPreviewAdk = computed(() => {
      let show = false;
      if (fieldsDataValue?.value?.preview) {
        show = true;
      }
      // switch (props.adkType) {
      //   case 'scope':
      //     show = true;
      //     break;
      //   case 'train':
      //     show = true;
      //     break;
      //   case 'research':
      //     show = true;
      //     break;
      //   case 'team':
      //     show = true;
      //     break;
      //   default:
      //     break;
      // }
      return show;
    });

    const lastActivityTime = ref(Date.now());
    const inactivityDialog = ref(false);
    const inactivityTime = ref(10);
    let intervalId2;

    const trackActivity = () => {
      if (props?.userType === 'participant') {
        lastActivityTime.value = Date.now();
      }
    };

    const checkActivity = () => {
      const currentTime = Date.now();
      const miutes = inactivityTime?.value * 60 * 1000; // 2 minutes in milliseconds

      if (currentTime - lastActivityTime.value >= miutes) {
        clearInterval(intervalId);
        inactivityDialog.value = true;
      }
    };

    onMounted(() => {
      if (props?.userType === 'participant') {
        // Set the interval to check for inactivity every minute (60,000 milliseconds)
        intervalId2 = setInterval(checkActivity, 60000);
      }
    });

    onBeforeUnmount(() => {
      if (props?.userType === 'participant') {
        // Cleanup before the component is unmounted
        clearInterval(intervalId2);
      }
    });

    const startTimer = () => {
      lastActivityTime.value = Date.now();
      inactivityDialog.value = false;
      initiateTimer(adkTypeValue?.value);
    };

    return {
      ...toRefs(color),
      ...toRefs(page),
      moduleName,
      getComponent,
      getColor,
      programDoc,
      adkIndex,
      adkTypeValue,
      capitalizeFirstLetter,
      fieldsData,
      userDocValue,
      setView,
      containerClass,
      fieldsDataValue,
      isPreviewAdk,
      isPreview,
      durationFormat,
      dynamicBgColor,
      trackActivity,
      inactivityDialog,
      startTimer
    };
  }
});
